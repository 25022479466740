@font-face {
  font-family: 'gilroy';
  src: url("settings/fonts/gilroy-regular.woff") format("woff"),
  url("settings/fonts/gilroy-regular.woff2") format("woff2"),
  url('settings/fonts/gilroy-regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'gilroy';
  src: url("settings/fonts/gilroy-medium.woff") format("woff"),
  url("settings/fonts/gilroy-medium.woff2") format("woff2"),
  url('settings/fonts/gilroy-medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'gilroy';
  src: url("settings/fonts/gilroy-bold.woff") format("woff"),
  url("settings/fonts/gilroy-bold.woff2") format("woff2"),
  url('settings/fonts/gilroy-bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'gilroy';
  src: url('settings/fonts/gilroy-extra-bold.otf') format('opentype'),
  url("settings/fonts/gilroy-bold.woff") format("woff"),
  url("settings/fonts/gilroy-bold.woff2") format("woff2");
  font-style: normal;
  font-weight: 800;
}

